import React from 'react'
import { RECOMMENDED_ARTICLES } from '../../../shared/constants';
import HomepageSlider from '../../../components/Slider/HomepageSlider';
import './HomepageRecommendedArticles.module.scss';

const HomepageRecommendedArticles = (props) => {
  const { isDesktop } = props;

  return (
    <div className='recommended-articles-wrapper'>
      <div className='recommended-articles-container'>
        <h2 className='recommended-articles-title'>Informasi Tepercaya Seputar Asuransi di Indonesia!</h2>
        <div className='recommended-articles-list'>
          {isDesktop && (<RecommendedArticlesDesktop />)}
          {!isDesktop && (<RecommendedArticlesMobile />)}
        </div>
      </div>
    </div>
  );
};

const recommendedArticles = () => RECOMMENDED_ARTICLES.map((article, index) => (
  <a
    className='recommended-articles-card'
    key={`recommended-article-${index}`}
    href={article.link}
    target="_blank" rel="noreferrer noopener"
  >
    <img src={article.image} alt="" className='recommended-articles-card-image' />
    <p className='recommended-articles-card-title'>{article.title}</p>
    <div className='recommended-articles-card-details'>
      <span className='recommended-articles-card-label'>{article.label}</span>
      <span className='recommended-articles-card-date'>{article.date}</span>
    </div>
  </a>
));

const RecommendedArticlesDesktop = () => (
  <>
    {recommendedArticles()}
  </>
);

const RecommendedArticlesMobile = () => (
  <HomepageSlider
    sliderData={{
      desktop: {
        height: 243,
        width: 262,
        visibleItems: 1,
      },
      mobile: {
        height: 240,
        width: 278,
      },
    }}
    items={recommendedArticles()}
  />
);

export default HomepageRecommendedArticles;